<template>
  <div style="padding: 20px">
    <!--条件搜索-->
    <div class="handle-box">
        <div>
            <select-area @areaListId="getAreaListId"/>
            <el-select v-model="query.followType" placeholder="回访形式" clearable
                       class="handle-select mr10">
                <el-option v-for="(item,index) in followTypeList" :key="index" :label="item.dictLabel"
                           :value="item.dictValue"></el-option>
            </el-select>
            <el-input v-model="query.coachName" clearable placeholder="请输入教练姓名" style="width: 160px"
                      class="mr10"></el-input>
            <el-input v-model="query.studentName" clearable placeholder="请输入学生姓名" style="width: 160px"
                      class="mr10"></el-input>
            <el-date-picker style="width: 260px;margin-right: 10px" v-model="dateSection"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
            <el-button type="primary" @click="getData()">搜索</el-button>
          <el-button type="info" @click="reset">重置搜索条件</el-button>
            <el-popconfirm @confirm="exportExcel"
                           title="确定要导出吗？"
            >
                <template #reference>
                    <el-button :loading="exportExcelSwitch!=true" type="primary">导出
                    </el-button>
                </template>
            </el-popconfirm>
        </div>
    </div>
    <el-table
            v-loading="tableLoading"
            :data="tableData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
    >
        <el-table-column prop="followName" label="回访对象"
                         width="130" show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="userId" label="回访形式" align="center" width="100">
            <template v-slot="{row}">
                <span>{{ row.followLabel }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="userId" align="center"
                         label="回访老师" width="100">
            <template v-slot="{row}">
                <span>{{ row.coachName }}</span>
            </template>
        </el-table-column>
        <el-table-column
                label="区域"
                prop="areaName"
                width="100">
        </el-table-column>
        <el-table-column
                label="校区"
                prop="campusName"
                width="150">
        </el-table-column>
        <el-table-column
                label="类型"
                prop="flagName"
                width="80">
        </el-table-column>
        <el-table-column
                label="回访时间"
                prop="followTime"
                width="180">
        </el-table-column>
        <el-table-column
                width="180"
                prop="createTime"
                label="提交时间">
        </el-table-column>
        <!-- <el-table-column
                align="center"
                label="回访图片"
                width="100">
            <template v-slot="products">
                <el-image class="code"
                        v-if="products.row.followImg"
                        hide-on-click-modal
                        :src="JSON.parse(products.row.followImg)[0]"
                        :preview-src-list="JSON.parse(products.row.followImg)"
                >
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
            </template>
        </el-table-column> -->
        <el-table-column
                show-overflow-tooltip="true"
                label="回访内容"
                prop="followContent">
        </el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination
                border
                layout="total, prev, pager, next"
                :current-page="query.pageIndex"
                :page-size="query.pageSize"
                :total="pageTotal"
                @current-change="handlePageChange"
        ></el-pagination>
    </div>
  </div>
</template>

<script>
  import {followExportExcel, pageList} from "@/api/follow";
  import {selectDictByType} from "@/api/dict";
  import selectArea from "../../components/SelectArea";

  export default {
    components: {
        selectArea
    },
    name: "follow",
    data() {
      return {
        followTypeList: [],
        dateSection: '',
        tableData: [],
        tableLoading: false,
        pageTotal: 0,
        exportExcelSwitch: true,
        query: {
          followType: null,
          coachName: '',
          studentName: '',
          pageIndex: 1,
          pageSize: 10,
          startDate: '',
          endDate: '',
          areaList: []
        }
      }
    },
    methods: {
        getData() {
            this.tableLoading = true;
            if (this.dateSection.length >= 2) {
                this.query.startDate = this.dateFormat(this.dateSection[0]);
                this.query.endDate = this.dateFormat(this.dateSection[1]);
            } else {
                this.query.startDate = '';
                this.query.endDate = '';
            }
            pageList(this.query).then(val => {
                this.tableLoading = false;
                if (val.code == 200) {
                    this.tableData = val.data.records;
                    this.pageTotal = val.data.total;
                } else {
                    this.$message.error("获取表格数据失败");
                }
            })
        },
        /**
         * 导出
         */
        exportExcel() {
            this.exportExcelSwitch = false;
            if (this.dateSection.length >= 2) {
                this.query.startDate = this.dateFormat(this.dateSection[0]);
                this.query.endDate = this.dateFormat(this.dateSection[1]);
            } else {
                this.query.startDate = '';
                this.query.endDate = '';
            }
            followExportExcel(this.query)
            this.exportExcelSwitch = true;
            this.loading = false

                // let blob = new Blob([res], {type: "application/vnd.ms-excel"});
                // console.log(blob);
                // let objectUrl = URL.createObjectURL(blob);
                // console.log(objectUrl);
                // const link = document.createElement('a');//我们用模拟q标签点击事件
                // const fname = '回访记录'; //下载文件的名字
                // link.href = objectUrl;
                // link.setAttribute('download', fname);
                // document.body.appendChild(link);
                // /**
                //  * 在这期间，我们可以做自己界面的反应逻辑
                //  **/
                // link.click();//点击
                // document.body.removeChild(link); // 下载完成移除元素
                // window.URL.revokeObjectURL(URL); // 释放掉blob对象

        },
        /**
         * 切换页面
         */
        handlePageChange(val) {
            this.query.pageIndex = val;
            this.getData();
        },
        getAreaListId(val) {
            this.query.areaList = val;
        },
        /**
         * 时间格式化
         */
        dateFormat(val) {
            var date = new Date(val)
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            const time = y + '-' + m + '-' + d
            return time
        },    // 重置
        reset() {
                this.dateSection = [],
                this.query.areaList = [],
                this.query.followType = null,
                this.query.coachName = '',
                this.query.studentName = '',
                this.query.pageIndex = 1,
                this.query.pageSize = 10,
                this.query.startDate = null,
                this.query.endDate = null
            this.getData()
        },
    },
    created() {
        this.getData();
        selectDictByType("follow").then(res => {
            if (res.code == 200) {
                this.followTypeList = res.data
            } else {
                this.$message.error("获取城市数据失败");
            }
        })
    }
  }
</script>

<style scoped>
    .handle-select {
        width: 140px;
    }

    .mr10 {
        margin-right: 10px;
    }

    .code {
        margin-top: 6px;
        width: 40px;
        height: 40px;
        border-radius: 8px
    }

    .handle-box {
        display: flex;
        margin-bottom: 20px;
    }
</style>
