import request from '../utils/request';


export function followExportExcel(query) {
    return request({
        url: '/sys/follow/followExportExcel',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        responseType: 'arraybuffer',
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}

export const pageList = (query) => {
    return request({
        url: 'sys/follow/pageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

